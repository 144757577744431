import React from "react"
import { graphql, Link } from "gatsby"
// import BackgroundImage from "gatsby-background-image"

import Layout from "../../components/layout/basic"
import SEO from "../../components/global/SEO"
import ProductCard from "../../components/shop/ProductCard"

// Bootstrap
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Nav from "react-bootstrap/Nav"

const Shop = ({ data: { image, categories, products } }) => {
  // const backgroundImage = [
  //   image.fluid,
  //   `radial-gradient(at 50% 100%, rgba(181, 45, 168, 0.6), rgba(15, 1, 94, 0.75))`,
  // ].reverse()

  return (
    <Layout>
      <SEO
        title="Каталог обладнання"
        description="Купити обладнання для інтернету й комфортного перегляду інтернет-телебачення в вашому будинку або для бізнес-використання"
        url="/shop/"
      />
      <div className="hero-background-light">
        <Container className="py-5">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Newtelecom</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Обладнання
              </li>
            </ol>
          </nav>
          <h1 className="header-gradient">Обладнання і пристрої</h1>
          <div className="hero-slogan mb-5">
            Рекомендоване обладнання для налаштування Інтернету і комфортного
            перегляду Інтернет-телебачення в вашому будинку або для
            бізнес-використання
          </div>
        </Container>
      </div>
      <section className="devices my-5">
        <Container>
          <Nav as="ul" className="mb-5">
            <Nav.Item>
              <Nav.Link disabled>Все обладнання</Nav.Link>
            </Nav.Item>
            {categories.nodes.map(category => (
              <Nav.Item key={category.id}>
                <Nav.Link as={Link} to={`/${category.seo.slug}/`}>
                  {category.categoryName}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          <Row>
            {products.nodes.map(product => (
              <ProductCard
                key={product.id}
                productTitle={product.productName}
                productPrice={product.productPrice}
                productImage={product.productImage.fluid}
                productSlug={`/${product.productCategory.seo.slug}/${product.seo.slug}`}
                isInStock={product.isInStock}
              />
            ))}
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default Shop

export const query = graphql`
  {
    image: contentfulAsset(id: { eq: "72cccbe9-87ae-5a4e-a9ef-a2063d504c4e" }) {
      title
      fluid(maxWidth: 1920) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    categories: allContentfulShopCategory(
      filter: { node_locale: { eq: "uk" }, isActive: { eq: true } }
    ) {
      nodes {
        id
        categoryName
        categoryImage {
          id
          fixed {
            src
          }
        }
        categoryDescription {
          content {
            content {
              value
            }
          }
        }
        seo {
          slug
          title
          metaDescription
          pageImage {
            fixed {
              src
            }
          }
        }
      }
    }
    products: allContentfulShopProduct(
      filter: { isActive: { eq: true }, node_locale: { eq: "uk" } } # sort: { fields: isInStock, order: ASC }
    ) {
      nodes {
        id
        productCategory {
          categoryName
          seo {
            slug
          }
        }
        productName
        productPrice
        isActive
        isInStock
        seo {
          slug
        }
        productDescription {
          json
        }
        productImage {
          fluid(maxWidth: 500, maxHeight: 500, resizingBehavior: PAD) {
            ...GatsbyContentfulFluid_withWebp
          }
          fixed(width: 500, height: 500, resizingBehavior: PAD, quality: 85) {
            ...GatsbyContentfulFixed_withWebp
          }
        }
      }
    }
  }
`
